body {
  background: #FFFFFF;
  font: normal 14px/22px Arial, Helvetica, sans-serif;
  color: #000000;
}


h1, h2, h3 {
  color: #333447;
}
a {
  color: #333447;
  text-decoration: none;
}

/*
a:hover {
  text-decoration: underline;
}
*/

.gray {
  color: #000000;
}

.blue {
  color: #333447;
}

#header {
  font-size: 50px;
  line-height: 55px;
  color: #333447;
  font-weight: bold;
}

#langmenu a {
  color: #000000;
}

#langmenu a.active {
  font-weight: bold;
  color: #333447;
}

#mainmenu a, #submenu a {
  color:#000000;
background: #FFFFFF;
  padding: 0px 5px 0px 5px;
}

#mainmenu a.active, #submenu a.active, #mainmenu strong {
  /* font-weight: bold; */
  color: #333447;
  background: #a2a3b7;

}
.gs-snippet {
color: black;
}
#bottomgif {
display: none;
}
